import React, { useState, useEffect } from "react"
import axios from "axios"
import jsonpAdapter from "axios-jsonp"

/* Import Local Styles */
import "./mailchimp.scss"

const Mailchimp = () => {
  const action = `https://protonmail.us5.list-manage.com/subscribe/post?u=875fd67f937e35449047072e5&amp;id=08c19c3066`
  const [status, setStatus] = useState()
  const [email, setEmail] = useState("")
  const [error, setError] = useState()
  const [submitDisabled, setSubmitDisabled] = useState(true)

  const handleSubmit = event => {
    event.preventDefault()
    axios
      .post("/do-not-research-api/mailchimp", {
        email,
      })
      .then(response => {
        console.log("response", response)
        setEmail("")
        setError()
      })
      .catch(function (error) {
        console.log("error", error)
        if (error.response.data.detail) {
          if (error.response.data.detail.includes("already a list member")) {
            console.log(`${email} is already subscribed to Do Not Research`)
            setError(`${email} is already subscribed to Do Not Research`)
          }
        } else if (error.response.data.message) {
          console.log(error.response.data.message)
          setError(`Error signing up for Do Not Research Email List`)
        } else {
          console.log("Error signing up for Do Not Research Email List")
          setError(`Error signing up for Do Not Research Email List`)
        }
      })
  }

  const handleChange = event => {
    setEmail(event.target.value)
    // test for validity of email
    const regex = /^([\w_\.\-\+])+\@([\w\-]+\.)+([\w]{2,10})+$/
    !regex.test(email) ? setSubmitDisabled(true) : setSubmitDisabled(false)
  }

  const sendData = url => {
    console.log("getting here?")
    console.log(url)
    axios({
      url: url,
      adapter: jsonpAdapter,
      callbackParamName: "c", // optional, 'callback' by default
    }).then(res => {
      console.log(res)
    })
  }

  return (
    <>
      <form onSubmit={handleSubmit} className="mailchimp">
        <input
          name="EMAIL"
          type="email"
          placeholder="email address"
          value={email}
          onChange={handleChange}
          required
        />
        <button disabled={submitDisabled} type="submit">
          Subscribe
        </button>
      </form>
      <div className="error">{error && <p>{error}</p>}</div>
    </>
  )
}

export default Mailchimp
