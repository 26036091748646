import React, { useRef } from "react"
import Clipboard from "react-clipboard.js"

/* Import Global Components */
import Page from "~components/page"
import Mailchimp from "~components/mailchimp"

/* Import Local Styles */
import "./about.scss"
import { useEffect } from "react/cjs/react.development"

const AboutPage = () => {
  return (
    <Page title="about">
      <div>
        <p>
          Do Not Research is a “detestable band of washed up millennial
          seapunks” (Lolcow) and “zoomers obsessed with Mark Fisher” (Buzzfeed).
          Founded in 2020, DNR began as a private Discord server gathered to
          discuss memetic propagation and the role of the internet in shaping
          emergent political trends. Today it is a publishing platform for
          writing, visual art, internet culture research and more.
        </p>

        <p>
          We periodically accept submissions. All contributors are invited to
          join the community. Follow us to stay up to date:
        </p>

        <p>
          Contact{" "}
          <a href="mailto:donotresearch@proton.me">
            {"<"}donotresearch@proton.me{">"}
          </a>
        </p>
        <p>
          Instagram{" "}
          <a
            href="https://www.instagram.com/do.not.research"
            target="_blank"
            rel="noreferrer"
          >
            {"<"}@do.not.research{">"}
          </a>
        </p>
      </div>
      <br />
      <p>Newsletter</p>
      <div>
        <Mailchimp />
      </div>
      <br />

      <div className="donations">
        <p>Donations</p>
        <Clipboard data-clipboard-text="0x8639eF5D0BA098edE9578aBC7858094cC80a8fBa">
          ETH 0x8639eF5D0BA098edE9578aBC7858094cC80a8fBa
        </Clipboard>
        <br />
      </div>
    </Page>
  )
}

export default AboutPage
